import {
  createSettingsParam,
  createStylesParam,
  SettingsParamType,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

import { KeyTransform } from '../helpers';

import {
  EAlignment,
  EImageRatio,
  COMPONENT,
  EGroupListSort,
  ELayoutType,
} from '../constants';

export const settings = {
  groupListTitle: createSettingsParam('groupListTitle', {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('groups-web.settings.TextTab.groups.default'),
  }),
  groupListSubtitle: createSettingsParam('groupListSubtitle', {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('groups-web.settings.TextTab.groups.subtitle.default'),
  }),
  groupMembersLabel: createSettingsParam('groupMembersLabel', {
    getDefaultValue: () => 'Members',
    type: SettingsParamType.Text,
  }),
  groupListLayout: createSettingsParam('groupListLayout', {
    type: SettingsParamType.String,
    dangerousKeyTransformationOverride: KeyTransform.global,
    getDefaultValue: () => {
      switch (COMPONENT.name) {
        case 'GroupListWidget':
          return ELayoutType.ListWidget;
        case 'SideBySideWidget':
          return ELayoutType.SideBySide;
        default:
          return ELayoutType.List;
      }
    },
  }),
  sortBy: createSettingsParam('sortBy', {
    type: SettingsParamType.String,
    getDefaultValue: () => EGroupListSort.Activity,
    dangerousKeyTransformationOverride: KeyTransform.global,
  }),
};

export const styles = {
  headerAlignment: createStylesParam('headerAlignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => EAlignment.Left,
  }),

  imageRatio: createStylesParam('imageRatio', {
    type: StyleParamType.Number,
    getDefaultValue: () => EImageRatio.Rectangle,
  }),

  sideBarAlignment: createStylesParam('sideBarAlignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => EAlignment.Right,
  }),

  groupCardGridSpacing: createStylesParam('groupCardGridSpacing', {
    getDefaultValue: () => 12,
    inheritDesktop: false,
    type: StyleParamType.Number,
  }),
  listWidgetSpacing: createStylesParam('listWidgetSpacing', {
    getDefaultValue: () => 0,
    inheritDesktop: false,
    type: StyleParamType.Number,
  }),
  sideBarHorizontalSpacing: createStylesParam('sideBarHorizontalSpacing', {
    getDefaultValue: () => 40,
    type: StyleParamType.Number,
  }),
  sideBarVerticalSpacing: createStylesParam('sideBarVerticalSpacing', {
    getDefaultValue: ({ isMobile }) => (isMobile ? 12 : 24),
    type: StyleParamType.Number,
  }),
  sideBarProportion: createStylesParam('sideBarProportion', {
    getDefaultValue: () => 30,
    type: StyleParamType.Number,
  }),

  listTitleColor: createStylesParam('listTitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  listSubtitleColor: createStylesParam('listSubtitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  listTitleFont: createStylesParam('listTitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Title', {
      size: 24,
      htmlTag: 'h1',
    }),
  }),
  listSubtitleFont: createStylesParam('listSubtitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: (params) =>
      wixFontParam('Body-M', {
        size: params.isMobile ? 20 : 16,
        htmlTag: 'p',
      })(params),
  }),
  searchSortingColor: createStylesParam('searchSortingColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  sidebarTitleFont: createStylesParam('sidebarTitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 16,
      htmlTag: 'h2',
    }),
  }),
  sidebarTitleColor: createStylesParam('sidebarTitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  showHeaderTitle: createStylesParam('showHeaderTitle', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),

  showHeaderSubtitle: createStylesParam('showHeaderSubtitle', {
    getDefaultValue: () => false,
    type: StyleParamType.Boolean,
  }),

  showSearchSorting: createStylesParam('showSearchSorting', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  // use in sidebar layout
  showCreateGroupButton: createStylesParam('showCreateGroupButton', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showSuggestedGroups: createStylesParam('showSuggestedGroups', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showFeedTitle: createStylesParam('showFeedTitle', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
};
